.App {
  text-align: center;
}

.form-errors {
  font-family: inherit;
  color: red;
  margin: 0px auto 8px;
  font-size: 14px;
}

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #6f7270;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

/* for responsive navbar */

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.side-links > li > a:hover,
.side-links > li > button:hover {
  background: rgba(16, 185, 129, 0.1);
}

.side-links > li > a.active,
.side-links > li > a.active:hover {
  background: rgba(16, 185, 129, 0.5);
}

.sidebar .side-dropdown {
  padding-left: 4rem;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.15s ease;
}

.sidebar .side-dropdown.show {
  max-height: 1000% !important;
}

.sidebar .side-dropdown a:hover {
  color: blue;
}

.toggle-btn > div {
  background-color: transparent !important;
}

.main-content {
  margin-left: 18rem;
}

.main-content-toggle {
  margin-left: 5rem;
  position: relative;
}

main {
  padding: 1.5rem;
  background: #ececec;
  min-height: calc(100vh - 70px);
  margin-top: 24px;
  z-index: 20;
}

.page-header {
  display: flex;
  justify-content: space-between;
}

/* Home page */
.home-page-content {
  width: 100%;
}

/* Signup page */

.recharts-legend-wrapper {
  top: -35px !important;
  height: 10px !important;
}

.rdt_TableRow {
  font-size: 14px !important;
}

.rdt_TableCell {
  min-width: 150px !important;
}

.rdt_TableCol {
  font-size: 15px !important;
  font-weight: 700;
}

.kYNaHB {
  opacity: 1 !important;
}

@media screen and (max-width: 1124px) {
  .header-sidebarr {
    width: 100% !important;
    padding-right: 1rem !important;
  }
}

.menu {
  font-size: 16px;
  position: absolute;
  top: 45px; /* Adjust this value to position the menu relative to the button */
  right: 0; /* Adjust this value to position the menu relative to the button */
  background-color: #fff;
  border: #272a2a;
  border-radius: 5px;
  width: 100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure the menu appears above other content */
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  padding: 3px;
  display: flex; /* Use flexbox layout */
  align-items: center;
  color: rgba(5, 6, 6, 0.7) /* Align items vertically */
}

.menu ul li .icon {
  margin-right: 5px; /* Adjust the value as needed */
}

.menu ul li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.profile-button {
  position: relative;
}

.profile-button button {
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
